import React from "react"
import styled from "styled-components"

import Layout from "../layouts/default"
import SEO from "../components/seo"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Pagina Non Trovata" />
    <Styled404>
      <h2>404</h2>
      <h1>Pagina Non Trovata</h1>
      <p>
        La pagina non esiste. Torna alla{" "}
        <AniLink cover bg="#fff" direction="top" duration={2} to="/">
          home
        </AniLink>
      </p>
    </Styled404>
  </Layout>
)

const Styled404 = styled.section`
  height: 75%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: relative;

  h1 {
    margin-bottom: 1rem;
  }

  h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;

    font-family: inherit;
    font-size: 10rem;
    opacity: 0.04;

    @media (min-width: 768px) {
      font-size: 20rem;
    }

    @media (min-width: 1204px) {
      font-size: 40rem;
    }
  }
`

export default NotFoundPage
